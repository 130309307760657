.field-add-name {
  width: 50%;
}

.user-pwd > .p-icon-field, .user-pwd-input {
  width: 100%;
}

.field-admin-checkbox {
  margin: auto;
  padding-bottom: 1.538rem;
  min-width: 16rem;
  width: 85%;
  display: flex;
}

.field-admin-checkbox > label {
  padding-left: 3px;
}
