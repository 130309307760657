.app-prof-table-header {
  display: flex;
}

.app-prof-table-header > .p-dropdown {
  width: 20rem;
}

.app-prof-table > .p-datatable-header {
  padding-left: 0;
}