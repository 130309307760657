.app-desc {
    white-space: normal
}

.version-table {
    padding-left: 5rem;
    margin-top: -1rem;
}


.app-table-container {
    padding: 0 1rem 1rem 1rem;
}


.version-app-table > .p-datatable-wrapper > table tr:last-child > td {
    border-bottom: none;
  
}