.settings-panel {
  padding: 2.5rem;
  display: flex;
}

.settings-sidebar {
  position: sticky;
  left: 0;
  height: calc(100vh - 9rem);
  display: flex;
  flex-direction: column;
}

.settings-menu {
  border: none;
  font-weight: 600;
  font-size: 1.077rem;
}

.sett-header-rem-empty {
  padding: 0;
}

.sett-header-rem {
  text-align: left;
  padding: 1rem 0;
  font-size: 1.2rem;
}

.sett-icon {
  margin-right: 0.5rem;
} 

.settings-content {
  padding-top: 0.75rem;
  max-width: 1478px;
  margin: 0 auto;
  min-width: 80%;
}

.item-active > .p-menuitem-content > .p-menuitem-link > .p-menuitem-icon, 
.item-active > .p-menuitem-content > .p-menuitem-link > .p-menuitem-text {
  color: #2196f3;  
}

.item-inactive > .p-menuitem-content > .p-menuitem-link > .p-menuitem-icon, 
.item-inactive > .p-menuitem-content > .p-menuitem-link > .p-menuitem-text {
  color: #6c757d;  
}

.item-inactive > .p-menuitem-content:hover, .item-active > .p-menuitem-content:hover{
  background: none !important;
}

.item-inactive.p-focus.p-menuitem > .p-menuitem-content, .item-active.p-focus.p-menuitem > .p-menuitem-content {
  background: none !important;
}