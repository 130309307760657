html {
  font-size: 13px;
}

.App {
  text-align: center;
}

.k-tabmenu {
  border: 1px solid #dee2e6;
  font-size: 1.077rem;
}

.k-tabmenu > ul {
  border: none;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
  border-bottom: 2px solid; 
}

.k-tabmenu .p-tabmenuitem:nth-last-child(2) {
  margin-left: auto;
}


.loginContainer {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  font-size: 64px;
  font-weight: bolder;
  align-items: center;
  justify-content: center;
}

.resultContainer,
.historyItem {
  flex-direction: row;
  display: flex;
  width: 400px;
  align-items: center;
  justify-content: space-between;
}

.historyContainer {
  flex-direction: column;
  display: flex;
  height: 200px;
  align-items: center;
  flex-grow: 5;
  justify-content: flex-start;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.inputContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.inputContainer, .k-input-password {
  width: 17rem;
}

.inputContainer > .errorLabel {
  color: red;
  font-size: 12px;
}

.btn-add {
  background-color: #16A34A;
  border-color: #16A34A;
}

.btn-edit {
  background-color: orange;
  border-color: orange;
}

.kirmash-icon {
  display:block;
  width:  2.357rem;
}

.add-dialog {
  width: 45rem;
  height: 75%;
}

.add-dialog-header {
  text-align: center;
  padding-left: 1.538rem;
}

.add-container {
  display: flex;
  flex-direction: column; 
}

.field-add-flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.field-add-flex-column {
  display: flex;
  flex-direction: column;
}

.add-container > .field-add, .add-container > .field-add-flex-row, .krm-check-list-wrapper, .krm-list-box-wrapper  {
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 1.538rem;
  min-width: 16rem;
  width: 85%;
}

.field-add > input, .field-add > .p-inputnumber, .field-add > .p-password  {
  width: 100%;
}

.field-add > label, .field-add-flex-column > label{
  padding-bottom: 5px;
  display: block;
  text-align: center;
}

.field-add > small, .field-add-flex-column > small{
  display: block;
  text-align: center;
}

.field-add .p-dropdown, .field-add > textarea  {
  width: 100%;
}

.field-add > textarea {
  min-width: 100%;
  height: 10rem;
}

.add-dialog-footer {
  text-align: center;
}

.ml-auto {
  margin-left: auto;
}

.app-table-header, .categories-table-header, .profiles-table-header, .users-table-header, .app-prof-table-header, .cat-prof-table-header, .stat-table-header {
  display: flex;
  flex-direction: row-reverse;
}

.app-table > .p-datatable-header, .categories-table > .p-datatable-header, .profiles-table > .p-datatable-header,
.app-prof-table > .p-datatable-header, .cat-prof-table > .p-datatable-header, .stat-table > .p-datatable-header ,
.users-table > .p-datatable-header{
  background-color: white;
  border: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
