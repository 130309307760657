.krm-list-box-wrapper {
    font-size: 0.75rem;
    margin-bottom: 0.8rem;
    height: 90%;
}

.krm-list-box-list-disabled {
    opacity: 0.6;
    pointer-events: none;
}

.krm-list-box-wrapper .krm-list-box-list,
.krm-list-box-list .p-listbox-list-wrapper {
    height: 100%;
    scrollbar-width: thin;
}

.krm-list-box-label {
    margin-bottom: 0.08rem;
    font-weight: 600;
    font-size: 0.9rem;
}

.krm-list-box-wrapper .p-listbox-list {
    padding: 0;
    font-size: 0.9rem;
}

.krm-list-box-wrapper .p-listbox-empty-message {
    padding: 0.3rem 0.4rem 0.2rem;
    color: var(--gray-400);
}

.kkrm-list-box-wrapper .p-listbox-list-wrapper {
    scrollbar-width: thin;
    scrollbar-color: #D9D9D9;
}

.krm-list-box-wrapper .p-listbox-item,
.krm-list-box-wrapper .p-listbox-header {
    padding: 0.5rem;
    color: #212121;
}
.krm-list-box-wrapper .p-listbox .p-listbox-list .p-listbox-item.p-highlight {
    background-color: #2196f3;
    color: white;
}

.krm-list-box-wrapper .p-listbox-header {
    background-color: rgba(221, 224, 255, 0.3);
}

.krm-list-box-wrapper .p-listbox-item:hover,
.krm-list-box-wrapper .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled).p-focus {
    background-color: #dde0ff4d;
}
