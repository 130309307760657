.add-app-icon {
    display: flex;
    flex-direction: row;
    align-content: space-between;
}

.add-app-icon > button {
    margin-left: auto;
}

.add-apk > label {
    display: flex;
    align-items: center;
    word-break: break-all;
    justify-content: center;
    margin-top: 1rem;
}

.add-app-image {
    height: 2.4rem;
}

.add-apk > button {
    width: 100%;
}

.add-apk {
    display: flex;
    flex-direction: column;
    
}

.add-version-dialog {
    width: 34rem;
    height: 56rem;
}

.add-version-dialog .p-dialog-content {
    display: flex;
}

.krm-add-version {
    flex-grow: 1;
}

.add-container-loading {
    position: absolute;
    top: 35%;
    left: 40%;
    z-index: 1000;
}

@layer primereact {
    .add-apk > .p-button {
        display: block;
        
    }
}